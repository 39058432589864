





















import { Component, Vue } from 'vue-property-decorator'
// @ts-ignore
import { StreamBarcodeReader } from 'vue-barcode-reader'
import ProductService from '@/services/ProductService'
import { namespace } from 'vuex-class'
const Product = namespace('Product')
const ModeBuyProduct = namespace('ModeBuyProduct')
@Component({
  components: {
    StreamBarcodeReader
  }
})
export default class BarCodeScan extends Vue {
  private message: string = ''
  private barcodeResult: string = ''
  public reader: any = null

  @Product.Action
  private SET_NEW_PRODUCT_SEARCH!: (data: string) => void
  @ModeBuyProduct.Getter
  private getModeBuyProduct!: number

  public handleChangeSearchValue() {
    this.SET_NEW_PRODUCT_SEARCH(this.message)
  }

  public onDecode(result: any) {
    this.SET_NEW_PRODUCT_SEARCH('')
    if (result) {
      ProductService.getProductBarCode(result, this.getModeBuyProduct).then(
        (response: { data: { items: { product_name: string } } }) => {
          this.barcodeResult = response.data.items.product_name
          if (this.barcodeResult !== '') {
            this.SET_NEW_PRODUCT_SEARCH(this.barcodeResult)
          }
        }
      )
    }
    this.$router.push({ name: 'product-search' })
  }
}
